import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { MainState } from './state';

const defaultState: MainState = {
  isLoggedIn: null,
  token: '',
  logInError: false,
  userProfile: null,
  dashboardMiniDrawer: false,
  dashboardShowDrawer: true,
  notifications: [],
  goodFilter: null,
  goods: [],
  allVendors: [],
  rowsPerPage: null,
  markFilter: null,
  marks: [],
  offerFilter: null,
  offers: [],
  vodozaporkaFilter: null,
  vodozaporka: [],
  makedocxFilter: null,
  makedocx: '',
  cablesFilter: null,
  cables: [],
  cablesHeaders: {},
  allRecipients: [],
};

export const mainModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
