import { MainState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    hasAdminAccess: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.is_superuser && state.userProfile.is_active);
    },
    loginError: (state: MainState) => state.logInError,
    dashboardShowDrawer: (state: MainState) => state.dashboardShowDrawer,
    dashboardMiniDrawer: (state: MainState) => state.dashboardMiniDrawer,
    userProfile: (state: MainState) => state.userProfile,
    token: (state: MainState) => state.token,
    isLoggedIn: (state: MainState) => state.isLoggedIn,
    firstNotification: (state: MainState) => state.notifications.length > 0 && state.notifications[0],
    goodFilter: (state: MainState) => state.goodFilter,
    goods: (state: MainState) => state.goods,
    allVendors: (state: MainState) => state.allVendors,
    rowsPerPage: (state: MainState) => state.rowsPerPage,
    markFilter: (state: MainState) => state.markFilter,
    marks: (state: MainState) => state.marks,
    offerFilter: (state: MainState) => state.offerFilter,
    offers: (state: MainState) => state.offers,
    vodozaporkaFilter: (state: MainState) => state.vodozaporkaFilter,
    vodozaporka: (state: MainState) => state.vodozaporka,
    cablesFilter: (state: MainState) => state.cablesFilter,
    cables: (state: MainState) => state.cables,
    cablesHeaders: (state: MainState) => state.cablesHeaders,
    makedocxFilter: (state: MainState) => state.makedocxFilter,
    makedocx: (state: MainState) => state.makedocx,
    allRecipients: (state: MainState) => state.allRecipients,
};

const {read} = getStoreAccessors<MainState, State>('');

export const readDashboardMiniDrawer = read(getters.dashboardMiniDrawer);
export const readDashboardShowDrawer = read(getters.dashboardShowDrawer);
export const readHasAdminAccess = read(getters.hasAdminAccess);
export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readToken = read(getters.token);
export const readUserProfile = read(getters.userProfile);
export const readFirstNotification = read(getters.firstNotification);
export const readGoodFilter = read(getters.goodFilter);
export const readGoods = read(getters.goods);
export const readAllVendors = read(getters.allVendors);
export const readRowsPerPage = read(getters.rowsPerPage);
export const readMarkFilter = read(getters.markFilter);
export const readMarks = read(getters.marks);
export const readOffersFilter = read(getters.offerFilter);
export const readOffers = read(getters.offers);
export const readVodozaporkaFilter = read(getters.vodozaporkaFilter);
export const readVodozaporka = read(getters.vodozaporka);
export const readCablesFilter = read(getters.cablesFilter);
export const readCables = read(getters.cables);
export const readCablesHeaders = read(getters.cablesHeaders);
export const readMakedocxFilter = read(getters.makedocxFilter);
export const readMakedocx = read(getters.makedocx);
export const readAllRecipients = read(getters.allRecipients);
