import axios from 'axios';
import { apiUrl } from '@/env';
import { IUserProfile, IUserProfileUpdate, IUserProfileCreate, IGoodFilter, IGood, IOfferFilter, IVodozaporkaFilter, ICablesFilter, IMakedocxFilter } from './interfaces';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: string, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  async getGoods(token: string, good_filter: IGoodFilter) {
    return axios.post(`${apiUrl}/api/v1/goods/`, good_filter, authHeaders(token));
  },

  async getAllVendors(token: string) {
    return axios.get(`${apiUrl}/api/v1/goods/all-vendors/`, authHeaders(token));
  },
  async getMarks(token: string, data: string) {
    let headers = authHeaders(token);
    headers.headers["content-type"] = 'application/json';
    return axios.post(`${apiUrl}/api/v1/goods/marks/`, JSON.stringify(data), headers);
  },
  async getOffers(token: string, offer_filter: IOfferFilter) {
    let headers = authHeaders(token);
    headers.headers["content-type"] = 'application/json'
    return axios.post(`${apiUrl}/api/v1/goods/offers/`, offer_filter, authHeaders(token));
  },
  async getVodozaporka(token: string, vodozaporka_filter: IVodozaporkaFilter) {
    return axios.post(`${apiUrl}/api/v1/goods/vodozaporka/`, vodozaporka_filter, authHeaders(token));
  },
  async getCables(token: string, cables_filter: ICablesFilter) {
    return axios.post(`${apiUrl}/api/v1/goods/cables/`, cables_filter, authHeaders(token));
  },
  async getCablesHeaders(token: string) {
    let headers = authHeaders(token);
    headers.headers["content-type"] = 'application/json'
    return axios.get(`${apiUrl}/api/v1/goods/cables-headers/`, headers);
  },
  async makeDocx(token: string, makedocx_filter: IMakedocxFilter) {
    // Отдаем все КП одним архивом
    // С особой циничностью задаем конфиг, в котором кроме хедера прописываем, что ждем в responseType байтики файла
    let config = {
        headers: {
            Authorization: `Bearer ${token}`,
            "content-type": 'multipart/form-data'
        },
        responseType: 'arraybuffer'
    }
    var formData = new FormData();
    for (const key in makedocx_filter) {
        formData.append(key, makedocx_filter[key]);
    }
    return axios.post(`${apiUrl}/api/v1/goods/makedocx/`, formData, config);
  },
  async getAllRecipients(token: string) {
    let headers = authHeaders(token);
    headers.headers["content-type"] = 'application/json'
    return axios.get(`${apiUrl}/api/v1/goods/all-recipients/`, headers);
  },
  async deleteRecipient(token: string, data: string) {
    let headers = authHeaders(token);
    headers.headers["content-type"] = 'application/json'
    return axios.post(`${apiUrl}/api/v1/goods/delete-recipients/`, JSON.stringify(data), headers);
  },
};
