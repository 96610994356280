import {IUserProfile, IGoodFilter, IGood, IMark, IOffer, IOfferFilter, IVodozaporka, IVodozaporkaFilter, ICables, ICablesFilter, IMakedocxFilter} from '@/interfaces';
import { MainState, AppNotification } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';


export const mutations = {
    setToken(state: MainState, payload: string) {
        state.token = payload;
    },
    setLoggedIn(state: MainState, payload: boolean) {
        state.isLoggedIn = payload;
    },
    setLogInError(state: MainState, payload: boolean) {
        state.logInError = payload;
    },
    setUserProfile(state: MainState, payload: IUserProfile) {
        state.userProfile = payload;
    },
    setDashboardMiniDrawer(state: MainState, payload: boolean) {
        state.dashboardMiniDrawer = payload;
    },
    setDashboardShowDrawer(state: MainState, payload: boolean) {
        state.dashboardShowDrawer = payload;
    },
    addNotification(state: MainState, payload: AppNotification) {
        state.notifications.push(payload);
    },
    removeNotification(state: MainState, payload: AppNotification) {
        state.notifications = state.notifications.filter((notification) => notification !== payload);
    },
    setGoodFilter(state: MainState, payload: IGoodFilter) {
        state.goodFilter = payload;

    },
    setGoods(state: MainState, payload: IGood[]) {
        state.goods = payload;
    },
    setAllVendors(state: MainState, payload: []) {
        state.allVendors = payload;
    },
    setRowsPerPage(state: MainState, payload: Number) {
        state.rowsPerPage = payload;
    },
    setMarkFilter(state: MainState, payload: string) {
        state.markFilter = payload;
    },
    setMarks(state: MainState, payload: IMark[]) {
        state.marks = payload;
    },
    setOffers(state: MainState, payload: IOffer[]) {
        state.offers = payload;
    },
    setOfferFilter(state: MainState, payload: IOfferFilter) {
        state.offerFilter = payload;
    },
    setVodozaporka(state: MainState, payload: IVodozaporka[]) {
        state.vodozaporka = payload;
    },
    setVodozaporkaFilter(state: MainState, payload: IVodozaporkaFilter) {
        state.vodozaporkaFilter = payload;
    },
    setCables(state: MainState, payload: ICables[]) {
        state.cables = payload;
    },
    setCablesFilter(state: MainState, payload: ICablesFilter) {
        state.cablesFilter = payload;
    },
    setCablesHeaders(state: MainState, payload: {}) {
        state.cablesHeaders = payload;
    },
    setMakedocxFilter(state: MainState, payload: IMakedocxFilter) {
        state.makedocxFilter = payload;
    },
    setMakedocx(state: MainState) {
        state.makedocx = 'yes';
    },
    setAllRecipients(state: MainState, payload: []) {
        state.allRecipients = payload;
    },
};

const {commit} = getStoreAccessors<MainState | any, State>('');

export const commitSetDashboardMiniDrawer = commit(mutations.setDashboardMiniDrawer);
export const commitSetDashboardShowDrawer = commit(mutations.setDashboardShowDrawer);
export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetToken = commit(mutations.setToken);
export const commitSetUserProfile = commit(mutations.setUserProfile);
export const commitAddNotification = commit(mutations.addNotification);
export const commitRemoveNotification = commit(mutations.removeNotification);

export const commitSetGoodFilter = commit(mutations.setGoodFilter);
export const commitSetGoods = commit(mutations.setGoods);
export const commitSetAllVendors = commit(mutations.setAllVendors);
export const commitSetRowsPerPage = commit(mutations.setRowsPerPage);

export const commitSetMarkFilter = commit(mutations.setMarkFilter);
export const commitSetMarks = commit(mutations.setMarks);

export const commitSetOfferFilter = commit(mutations.setOfferFilter);
export const commitSetOffers = commit(mutations.setOffers);

export const commitSetVodozaporkaFilter = commit(mutations.setVodozaporkaFilter);
export const commitSetVodozaporka = commit(mutations.setVodozaporka);

export const commitSetCablesFilter = commit(mutations.setCablesFilter);
export const commitSetCables = commit(mutations.setCables);
export const commitSetCablesHeaders = commit(mutations.setCablesHeaders);

export const commitSetMakedocxFilter = commit(mutations.setMakedocxFilter);
export const commitSetMakedocx = commit(mutations.setMakedocx);
export const commitSetAllRecipients = commit(mutations.setAllRecipients);
